import styled from "styled-components";

export const SignOsButton = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 500;
  color: #181c39;
  background-color: #fdc400;
  border-radius: 24px;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  transition: color 0.3s ease, transform 0.3s ease;
  width: max-content;

  &:before {
    content: "";
    position: absolute;
    top: -150%;
    left: -150%;
    width: 150%;
    height: 150%;
    background-color: #181c39;
    transform: skewX(45deg);
    transition: top 0.3s ease, left 0.3s ease;
    z-index: 0; /* Ensure it stays behind the text */
  }

  &:hover {
    color: #f6f6f9;
    transform: translateY(-3px);
  }

  &:hover:before {
    top: 0;
    left: 0;
  }

  span {
    position: relative;
    z-index: 1; /* Ensure text stays above the background */
  }
`;

export const ErrorMessage = styled.div`
  margin: 120px auto;
  width: 100%;
  color: red;
`;

export const StyledButton = styled.button`
  display: flex;
  width: 19.25rem;
  height: 4.3125rem;
  padding: 1.25rem 3.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  border-radius: 1.25rem;
  background: #006eb2;
  border: none;

  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.5rem;
  cursor: pointer;
  overflow: hidden;
`;

export const GradientEllipse = styled.div`
  width: 27.8125rem;
  height: 27.625rem;
  flex-shrink: 0;
  border-radius: 27.8125rem;
  opacity: 0.45;
  background: rgba(42, 114, 221, 0.8);
  filter: blur(225.8274383544922px);
`;
